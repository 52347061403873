<template>
	<div class="mobile-menu scroll-customize">
		<div class="mobile-menu-additionals">
			<CurrencyChanger />
			<LocaleChanger />
		</div>
		<div class="btn-group">
			<NuxtLinkLocale v-if="!isLoggedIn" to="/registration" class="btn btn-secondary btn-auth">{{ $t('header.register') }}</NuxtLinkLocale>
			<NuxtLinkLocale to="/steam-topup" class="btn btn-steam">
				<img src="/assets/images/svg/steam.svg" alt="">
				{{ $t('header.top_up_steam') }}
			</NuxtLinkLocale>
		</div>
		<div v-if="isLoggedIn" class="mobile-menu-balance ">
			{{ $t('header.balance') }}
			<span>{{ user.user.balance ? getCurrentCurrencyString(user.user.balance) : 0 }}</span>
			<NuxtLinkLocale to="/payments">{{ $t('header.replenish') }}</NuxtLinkLocale>
		</div>
		<div class="mobile-menu-catalog-links-box">
			<div class="mobile-menu-catalog-links-box-item">
				<p class="mobile-menu-catalog-links-title">{{ $t('header.by_directions') }}</p>
				<div class="mobile-menu-catalog-links">
					<button v-for="item in majorCategories" @click="[setChosenCategory(item.id), setIsCatalogOpen(true)]">{{ item.name }}</button>
				</div>
			</div>
			<div class="mobile-menu-catalog-links-box-item">
				<p class="mobile-menu-catalog-links-title">{{ $t('header.by_subcategories') }}</p>
				<div class="mobile-menu-catalog-links">
					<button v-for="item in allProductTypes" @click="[setChosenCategory(item.slug), setIsCatalogOpen(true)]">{{ item.name }}</button>
				</div>
			</div>
		</div>
		<div class="mobile-menu-links">
			<NuxtLinkLocale to="/store-create" class="icon-star">{{ $t('header.create_store') }}</NuxtLinkLocale>
			<NuxtLinkLocale to="https://seller.gstock.org/admin/login" class="icon-store" target="_blank" :external="true">{{ $t('header.enter_store') }}</NuxtLinkLocale>
			<NuxtLinkLocale to="/for_customer">{{ $t('header.for_customer') }}</NuxtLinkLocale>
			<NuxtLinkLocale to="/for_seller">{{ $t('header.for_seller') }}</NuxtLinkLocale>
			<NuxtLinkLocale to="/faq">{{ $t('header.faq') }}</NuxtLinkLocale>
			<NuxtLinkLocale to="/blog">{{ $t('header.articles') }}</NuxtLinkLocale>
		</div>
		<button v-if="isLoggedIn" @click="logout" class="btn-logout">{{ $t('header.logout') }}</button>
	</div>
</template>

<script setup lang="ts">
import { useCatalog } from '~/composables/catalog/useCatalog';
import { useUserAuth } from '~/composables/user/UseUserAuth';

const localePath = useLocalePath()
const { locale } = useI18n()
const { isLoggedIn, user } = storeToRefs(useUserStore())
const { allProductTypes, majorCategories } = storeToRefs(useCatalogStore())
const emits = defineEmits<{ close: [] }>()
const { getCurrentCurrencyString } = useCurrencyHelpers()
const { userLogout } = useUserAuth()
const { getCategories } = useCatalog();
const { setIsCatalogOpen, setChosenCategory } = useCatalogStore()

const logout = async () => {
	navigateTo(localePath('/'))
	userLogout()
}

await callOnce('getCategories', getCategories)

useEventListener('keyup', (e) => {
	if (e.key === 'Escape' || e.key === 'Backspace') emits('close')
})
</script>
<style lang="scss" scoped>
.mobile-menu {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: #232143;
	border-radius: 0 0 13px 13px;
	padding: 36px 20px 20px;
	transform: translateY(-16px);
	max-height: 90vh;
	max-height: 90dvh;
	overflow-y: auto;
}

.mobile-menu-balance {
	display: flex;
	gap: 8px;
	padding: 6px 0 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 109%;
	letter-spacing: -0.03em;
	color: #fff;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	margin-bottom: 16px;

	span {
		font-weight: 700;
		margin-left: auto;
	}

	a {
		border-radius: 29px;
		padding: 6px 10px 4px 10px;
		background: #da4727;
		display: block;
		font-weight: 700;
		font-size: 12px;
		line-height: 80%;
	}
}

.mobile-menu-catalog-links-box {
	display: flex;
	flex-direction: column;
	gap: 17px;
	margin-bottom: 30px;
}

.mobile-menu-catalog-links-box-item {
	display: flex;
	flex-direction: column;
	gap: 12px;
	color: white;

	button:last-child {
		padding-bottom: 0;
		border-bottom: none;
	}
}

.mobile-menu-catalog-links-title {
	font-weight: 600;
	font-size: 10px;
	line-height: 80%;
	letter-spacing: 0.09em;
	text-transform: uppercase;
	color: #848398;
}

.mobile-menu-catalog-links {
	display: flex;
	flex-direction: column;
	font-weight: 400;
	font-size: 18px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #fff;

	button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 13px 0;
		border-bottom: 1px solid #35325c;
		cursor: pointer;

		&:first-child {
			padding-top: 0;
		}

		&::after {
			content: url('assets/images/svg/arrow-right.svg');
			display: block;
			width: 16px;
			height: 14px;
			filter: invert(41%) sepia(37%) saturate(4564%) hue-rotate(345deg) brightness(87%) contrast(95%);
		}
	}
}

.mobile-menu-links {
	display: flex;
	flex-direction: column;
	gap: 14px;
	font-weight: 400;
	font-size: 16px;
	line-height: 11px;
	letter-spacing: -0.03em;
	color: #fff;
	margin-bottom: 20px;

	a {
		display: flex;
		align-items: center;
		gap: 8px;

		&.icon-star {
			&::before {
				content: url('/assets/images/svg/star-2.svg');
				width: 14px;
				height: 14px;
			}
		}

		&.icon-store {
			&::before {
				content: url('/assets/images/svg/storefront.svg');
				width: 14px;
				height: 14px;
			}
		}
	}
}

.mobile-menu-additionals {
	display: flex;
	gap: 28px;

	&>* {
		flex: 1;
	}
}

.mobile-menu__lang {
	display: flex;
	align-items: center;
	gap: 6px;
	padding-top: 12px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	font-weight: 400;
	font-size: 16px;
	line-height: 109%;
	letter-spacing: -0.03em;
	color: #fff;

	.lang-arrow {
		filter: brightness(0) invert(1);
		opacity: 50;
	}
}

.btn-group {
	display: flex;
	flex-direction: column;
	gap: 9px;
	margin: 16px 0 12px;

	&>.btn {
		width: 100%;
	}
}

.btn-steam {
	color: #232143;
	border: 1px solid #fff;
	background: #fff;
	transition: background .3s ease;
	align-items: center;

	img {
		width: 20px;
		height: 20px;
	}

	&:not(disabled):hover {
		opacity: 0.8;
	}
}

.btn-logout {
	font-weight: 400;
	font-size: 16px;
	line-height: 109%;
	letter-spacing: -0.03em;
	color: rgba(255, 255, 255, 0.5);
	padding-top: 17px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	margin-top: 12px;
	width: 100%;
	cursor: pointer;
}

.header__lang {
	color: white;

	:deep(.lang-item) {
		color: #67648E;
	}
}

@media (max-width:1023px) {
	.mobile-menu {
		display: block;
	}
}
</style>